const green = '#2CB87D';

export const colors = {
  green,
  blue: '#1322a8',
  orange: '#f05b3a',
  'black-1': '#4a4a4a',
  'color-gray-light': '#F1F1F2',
  'color-gray-extra-light': '#f9f9f9',
  'table-header-gray': '#fafafa',
  'light-purple': '#F6F5FA',

  // Green
  primary: {
    main: green,
    contrastText: '#fff',
  },

  // Blue
  secondary: {
    main: '#1D68D9',
    contrastText: '#fff',
  },
};

export const fonts = {
  sansSerif:
    '"Source Sans Pro", "Roboto", "Helvetica", "Myriad Pro", "Arial", sans-serif',
  monospace:
    'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
};
